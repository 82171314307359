// custom typefaces
import 'typeface-merriweather'
import 'typeface-open-sans'

import 'antd/dist/antd.css' // or 'antd/dist/antd.less'

// normalize CSS across browsers
// import './src/normalize.css'
// custom CSS styles
import './src/css/index.scss'

// Highlighting for code blocks
import 'prismjs/themes/prism-coy.css'
